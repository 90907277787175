import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ReadMoreReact from 'read-more-react/dist/components/ReadMoreReact';

const Favorites = () => {
    const [userimage, setUserimage] = useState("/images/blank.png");
    const [searchedData, setSearchedData] = useState([]);
    const [data, setData] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    
    const [blockData, setBlockData] = useState([]);
    const [formfilled, setFormfilled] = useState("notempty");
    const [input, setInput] = useState({});
    const [update, setUpdate] = useState(0)
    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value === '' ? null : value);
    };
    const currentLogin = JSON.parse(window.localStorage.getItem("user"));

    const blockDataShow = () => {
        axios.get('https://domaintobesocial.com/domaintobe/blockget', {
            params: {
                'userid': currentLogin.value
            }
        })
            .then(response => {
                if (response && response.data && response.data.message) {
                    setBlockData(response.data.message);
                } else {
                    console.log('No data or unexpected data format in the response.');
                }
            })
            .catch(err => {
                setBlockData([]);
                console.error('Error fetching block data:', err.message);
            });
    };
    let curentlogin = JSON.parse(window.localStorage.getItem("user"));
    const parseURLParams = (url) => {
        var queryStart = url.indexOf("?") + 1,
            queryEnd = url.indexOf("#") + 1 || url.length + 1,
            query = url.slice(queryStart, queryEnd - 1),
            pairs = query.replace(/\+/g, " ").split("&"),
            parms = {},
            i,
            n,
            v,
            nv;

        if (query === url || query === "") return;

        for (i = 0; i < pairs.length; i++) {
            nv = pairs[i].split("=", 2);
            n = decodeURIComponent(nv[0]);
            v = decodeURIComponent(nv[1]);

            if (!parms.hasOwnProperty(n)) parms[n] = [];
            parms[n].push(nv.length === 2 ? v : null);
        }
        return parms;
    };
    useEffect(()=>{
        const fetchData = async () => {
            try {
                const urlString = window.location.href;
                const urlParams = parseURLParams(urlString);

                if (urlParams?.comment) {
                    // setLoading(true);

                    const formData = new FormData();
                    formData.append("search", urlParams.comment[0]);

                    const searchRes = await axios.post(
                        "https://domaintobesocial.com/domaintobe/searchnewsfeed",
                        formData
                    );
                    console.log("searchNewFeed", searchRes.data.message);

                    setSearchedData(searchRes.data.message);

                    const userPostsFormData = new FormData();
                    userPostsFormData.append("id", searchRes.data.message[0]?.id);

                    const userPostsRes = await axios.post(
                        "https://domaintobesocial.com/domaintobe/getuserspostsbyid",
                        userPostsFormData
                    );
                    console.log("res", userPostsRes);

                    setData(userPostsRes.data.message);
                    setSearchedData([]);
                    // setLoading(false);
                } else {
                    const formData1 = new FormData();
                    formData1.append("userid", curentlogin.value);
                    formData1.append("id", curentlogin.value);

                    const newFeedsRes = await axios.post(
                        "https://domaintobesocial.com/domaintobe/getnewfeeds",
                        formData1
                    );
                    if (newFeedsRes.data.message !== "false") {
                        setData(newFeedsRes.data.message);
                    }

                    // setLoading(false);
                }
            } catch (error) {
                console.error(error.message);
                // setLoading(false);
            }
        }
        fetchData()

    },[update])

    const postLike = (i, id) => {
        if (formfilled === 'empty') {
            alert('Complete your personal details');
            window.location = "/userprofile";
            return false;
        } else {
       
            const userId = JSON.parse(window.localStorage.getItem("user"));
            const formData = new FormData();

            formData.append('userid', userId.value);
            formData.append('postid', id);
            formData.append('feedname', data[i].username);
            formData.append('feedemail', data[i].useremail);
            formData.append('postuser', data[i].postuser);
            formData.append('posts', data[i].posts);
            formData.append('sendername', "");
            formData.append('senderemail', "");

            axios.post('https://domaintobesocial.com/domaintobe/postlike', formData)
                .then((res) => {
                    if (res.data.message == 'success') {
                        setUpdate(update + 1)
                     
                        // window.location.reload();
                    } else {
                        alert(res.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }
    };

    const filteredData = filterValue
        ? data
            .filter((item) => item.username.toLowerCase().includes(filterValue.toLowerCase()))
            .map((item) => ({ ...item, username: item.username.toLowerCase() }))
        : data.map((item) => ({ ...item, username: item.username.toLowerCase() }));
  return (
      <div className="in_center in_center_discussion">

          <div className="head pr-0">
              <form className="d-flex w-100" > 
                  <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={filterValue}
                      onChange={handleFilterChange}
                  />
                  <button className="btn" type="submit">
                      <img src="images/searchicon.png" alt="icon" />
                  </button>
              </form>
          </div>

          {data.length > 0 ? (
              <div className="listusr favoriteclass mt-4">
                  <div className="row">
                      {data.map((result, i) => (
                          <React.Fragment key={i}>
                              {blockData &&
                                  blockData.filter(
                                      (item) =>
                                          item.friendid.includes(result.postuser) && item.status === 1
                                  ).length > 0 ? (
                                  ''
                              ) : ( 
                                  <div className="col-sm-6 col-lg-4 col-xl-3">
                                      <div className="test">
                                              <a onClick={() => postLike(i, result.id)}>
                                                  {result.userlike == "1" ? (
                                                      <img
                                                          className="hearticon"
                                                          src="images/iconS8.png"
                                                          align="icon"
                                                          style={{ filter: "none" }}
                                                      />
                                                  ) : (
                                                      <img
                                                          className="hearticon"
                                                          src="images/iconS8.png"
                                                          align="icon"
                                                      />
                                                  )}
                                              </a>
      <div className="asuser">
        <Link to={{ pathname: '/viewprofile/' + result.username }}>
          <span className="userimg">
            <img src={result.userimage ? result.userimage : userimage} align="icon" />
          </span>
          <h5>
            {result.username}{' '}
            {result.counttaguser === 2 ? (
              <span>
                is with
                {result.taggedusers.map((taggeduser, i) => (
                  <p key={i}>{taggeduser.name}</p>
                ))}
              </span>
            ) : (
              <span></span>
            )}
          </h5>
        </Link>
        <p>{result.created} Ago</p>
      </div>
      <Link to={`/Notification/${result.id}`}>
     <div className="img">
     <ReadMoreReact 
                    text={result.posts}
                    numberOfLines={1}
                    showLessButton={true}
                    readMoreText="click here to read more"
                   />
     
     </div>
     </Link>
      <ul className="likecomment">
        <li>
          <img src="images/like.png" alt="ion" /> {result.likes}
        </li>
        <li>
          <img src="images/comment.png" alt="ion" /> {result.comments}
        </li>
      </ul>
    </div>
                                  </div>
                              )}
                          </React.Fragment>
                      ))}
                  </div>
              </div>
          ) : (
              'No record found'
          )}


      </div>
  )
}

export default Favorites