import React, { useRef, useState } from 'react';
import UserStories from './UserStories';
import axios from 'axios';
import $ from 'jquery'
import swal from 'sweetalert';

const StorySection = () => {
    const modalRef = useRef(null);

    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [uploadtype, setuploadtype] = useState(null);
    const [update, setupdate] = useState(0);
    const [loading,setloading] = useState(false)

    const handleTitleChange = (e) => {
      setTitle(e.target.value);
    };
  
    const handleFileChange = (e) => {
      const files = Array.from(e.target.files); // Convert FileList to Array
      setFile(files);
      const types = files.map(file => 
        file.type === 'image/jpeg' ? 'image' : file.type === 'video/mp4' ? 'video' : ''
      );
      setuploadtype(types);
    };
    
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const userId = JSON.parse(window.localStorage.getItem("user"));
      const formData = new FormData();
      setloading(true);
      
      formData.append('user_id', userId.value);
      
      if (file !== null && file.length > 0) {
        file.forEach((file, index) => {
          formData.append(`files[${index}]`, file); // Append multiple files with unique keys
          formData.append(`file_type[${index}]`, uploadtype[index]); // Append corresponding file type
        });
        
        try {
          const response = await axios.post('https://domaintobesocial.com/domaintobe/api/stories', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
    
          if (response.status === 201) {
            swal("Successfully", "Story created successfully.", "success");
            setFile(null);
            setloading(false);
            if (modalRef.current) {
              window.$(modalRef.current).modal('hide');
              setupdate(update + 1);
            }
          } else {
            console.error('Error uploading story:', response.statusText);
          }
        } catch (error) {
          console.error('Error uploading story:', error);
        }
      } else {
        swal("Error", "Please select files", "error");
        setloading(false);
      }
      setFile(null);
    };
    

  return (
    <div className="story-section">
      <div className=""  data-toggle="modal" data-target="#exampleModalCenter2">
        <div className="">
          <div className="">
            {/* <span>+</span> */}
            {/* <img src='images/stroy.png'/> */}
            <div className="story-hight">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlRYwS5A0rCCfblZi-Tt2sj8U7LzDZ1u6x1g&s" alt="" width="100"/>
            <div className="story-add">
               <button type="button"><i className="fa-solid fa-plus"></i></button>
               <h3>Create story</h3>
            </div>
         </div>
          </div>
          {/* <p>Create Story</p> */}
        </div>
      </div>
   

      <div className="modal fade"  ref={modalRef} id="exampleModalCenter2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalCenterTitle">Create Story</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <form onSubmit={handleSubmit} className="add-story-form">
      {/* <div className="form-group">
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter story title"
        />
      </div> */}
      <div className="form-group">
        <label htmlFor="file">Upload Story</label><br/>
        <input
          type="file"
          id="file"
          onChange={handleFileChange}
          accept="image/*,video/*"
          multiple
        />
      </div>
    
      <button type="submit" className="btn btn-primary">Add Story</button>  {loading?"loading...":""}
    </form>
      </div>
  
    </div>
  </div>
</div>
      <UserStories update1={{update}}/>
    </div>
  );
};

export default StorySection;
