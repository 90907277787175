import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MessageList from '../MessageList/MessageList';
import { error } from 'jquery';
import { update } from 'firebase/database';
import swal from "sweetalert";
const Notificationpop = () => {
  const [filterValue, setFilterValue] = useState('');
  const [notification, setNotification] = useState([]);
  const[update,setupdate]=useState(0)
  let curentlogin = JSON.parse(window.localStorage.getItem("user"));
  const handleFilterChange = (event) => {
      setFilterValue(event.target.value);
  };

  useEffect(() => {
      const fetchData = async () => {
          try {
              const formData1 = new FormData();
              formData1.append('id', curentlogin.value); 
              const response = await axios.post('https://domaintobesocial.com/domaintobe/getnotifications', formData1);
              setNotification(response.data.message);
          } catch (error) {
              console.error(error.message);
          }
      };

      fetchData();
  }, [update]);

 let  deletenotifications =(id)=>{
  const formData1 = new FormData();
  formData1.append('id', id); 
  formData1.append('userId', curentlogin.value); 
  axios.post(`https://domaintobesocial.com/domaintobe/getdeletenotification`,formData1)
  .then((response) => {
      setupdate(update+1)
      swal({
          title: "Success!",
          text: "delete successfully done",
          icon: "success",
          confirmButtonText: "Ok",
        });
  })
  .catch(error=>console.log(error.message))
 }


const filteredNotifications = notification.filter(notification =>
  !notification.isdisabled.includes(curentlogin.value)
);

const filteredCount = filteredNotifications.length;

  return (
    <div><div className="notification">

    <a href="#"></a>
    <div className="notBtn">
      <a href="#">
        {/* Notification Number */}
        <div className="number">{filteredCount}</div>
        <i className="fas fa-bell" aria-label="Notifications" />
        {/* Notification Dropdown */}
      </a>
      <div className="box">
        <div className="display">
       
          { filteredCount > 0 ?notification.map((result, i) => (
            <React.Fragment key={i}>
         
                <>
                    {result.isdisabled.includes(curentlogin.value)?'':
             <div className="cont">
             <Link to={`/Notification/${result.postId}`}>
            <div className="sec new">
              <a href="#"></a>
              <a href="">
                <div className="profCont">
                  <img className="profile" src={result.image} alt="user"  />
                </div>
                <div className="txt">
                <b>{result.name}</b> {result.action} By {result.posts}
                <p className="text-muted">{result.action === 'Comment on post' ? result.description : ''}</p>
                </div>
                <div className="txt sub">{result.created}</div>
                <i onClick={()=>deletenotifications(result.id)} className="fas fa-trash-alt deletenotification"></i>
              </a>
              
            </div>
            </Link>
          </div>}
          
          </>
                             
                            </React.Fragment>)): <div className="cont"> <div className="txt "> No record found</div></div>}
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Notificationpop