import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import swal from 'sweetalert';

const EditDiscussion = () => {
    const [searchInput, setSearchInput] = useState('');
    const [searchedData, setSearchedData] = useState([]);
    const [data, setData] = useState([]);
    const [enteredText, setEnteredText] = useState('');
    const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
    const [videosPreviewUrls, setVideosPreviewUrls] = useState([]);
    const [linkUrl, setLinkUrl] = useState('');
    const [files, setFiles] = useState([]);
    const [userImage, setUserImage] = useState('/images/blank.png');
    const [videos, setVideos] = useState([]);
    const [childVisible, setChildVisible] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [formfilled, setFormFilled] = useState('');
    let {id} =useParams();
  
  
    useEffect(() => {
      const curentlogin = JSON.parse(window.localStorage.getItem("user"));
      const formData1 = new FormData();
      formData1.append('uid', curentlogin.value);
      formData1.append('pid', id);
  
      try {
        axios.post('https://domaintobesocial.com/domaintobe/getDiscussionById', formData1)
          .then(res => {
            if (res.data.details.length === 0) {
              setData(res.data.details);
            } else {
              setEnteredText(res.data.details.description);
              setImagesPreviewUrls(res.data.details.images.split(','));
            //   setVideosPreviewUrls(res.data.details.videos.split(','));
            }
          });
      } catch (e) {
        console.log('e-message', e.message);
      }
    }, [id]);
    const handleSearch = async (event) => {
      event.preventDefault();
      
      try {
        const formData = new FormData();
        formData.append('search', searchInput);
        
        const response = await axios.post('https://domaintobesocial.com/domaintobe/searchnewsfeed', formData);
  
        setSearchedData(response.data.message);
      } catch (error) {
        console.log(error.message);
      }
    };
  
    const handleChange = (event) => {
      setSearchInput(event.target.value);
    };
    const postClick = async (i) => {
     
  
      try {
        const formData = new FormData();
        formData.append('id', i);
  
        const response = await axios.post('https://domaintobesocial.com/domaintobe/getuserspostsbyid', formData);
  
        setData(response.data.message);
        setSearchedData([]);
     
      } catch (error) {
        console.log(error.message);
      }
    };
  
    
    const _handleImageChange = (e) => {
      e.preventDefault();
      let selectedFiles = Array.from(e.target.files);
  
      selectedFiles.forEach((file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          const filesize = Math.round(file.size / 1024);
          if (filesize > 2048) {
            swal("Oops!", "File too large, please select a file less than 2mb", 'error');
          } else {
            setFiles((prevFiles) => [...prevFiles, file]);
            setImagesPreviewUrls((prevUrls) => [...prevUrls, reader.result]);
          }
        };
        reader.readAsDataURL(file);
      });
    };
  
    const _handleVideoChange = (e) => {
      e.preventDefault();
      let selectedFiles = Array.from(e.target.files);
  
      selectedFiles.forEach((file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          const filesize = Math.round(file.size / 1024);
          if (filesize > 2048) {
            swal("Oops!", "File too large, please select a file less than 2mb", 'error');
          } else {
            setVideos((prevVideos) => [...prevVideos, file]);
            setVideosPreviewUrls((prevUrls) => [...prevUrls, reader.result]);
          }
        };
        reader.readAsDataURL(file);
      });
    };
    const removeImage = (i) => {
      const newArray = [...imagesPreviewUrls];
      newArray.splice(i, 1);
      setImagesPreviewUrls(newArray);
    };
  
    const removeVideo = (i) => {
      const newArray = [...videosPreviewUrls];
      newArray.splice(i, 1);
      setVideosPreviewUrls(newArray);
    };
  
    const handleChangelinkUrl = (event) => {
      setLinkUrl(event.target.value);
    };
    const onClick = () => {
      setChildVisible((prevChildVisible) => !prevChildVisible);
    };
  
    const urlify = (postcontent) => {
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      return postcontent.toString().replace(urlRegex, (url) => {
        return `<a href="${url}" >${url}</a>`;
      });
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
  
        if (formfilled === 'empty') {
          alert('Complete your personal details');
          window.location = "/userprofile";
          return false;
        } else {
          document.getElementById('loadingicon').style.display = 'block';
  
          const postcontent = enteredText;
          const html = urlify(postcontent);
  
          const obj = JSON.parse(window.localStorage.getItem("user"));
          const formData = new FormData();
          formData.append('userid', obj.value);
          formData.append('postid', id);
          formData.append('post', html);
          formData.append('tagged', checkedItems);
          formData.append('selectVideo', JSON.stringify(videosPreviewUrls));
          formData.append('selectImage', JSON.stringify(imagesPreviewUrls));
  
          files.forEach((file) => formData.append('files[]', file));
          videos.forEach((file) => formData.append('videos[]', file));
          formData.append('tagged', JSON.stringify(checkedItems));
  
          try {
            const res = await axios.post('https://domaintobesocial.com/domaintobe/updatenewsfeed', formData);
            document.getElementById('loadingicon').style.display = 'none';
            setEnteredText('');
            setImagesPreviewUrls([]);
            setVideosPreviewUrls([]);
  
            if (res.data.message === 'success') {
              // Call the equivalent of componentDidMount() using useEffect
            } else {
              alert(res.data.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
  
    };
  
    const updateState = (event) => {
      setEnteredText(event.target.value);
    };
    return (<>
      <div className="in_center in_centeredit"> <div className="head">
        <form className="d-flex" onSubmit={handleSearch}>
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search"
            name="search"
            aria-label="Search"
            autoComplete="off"
            onChange={handleChange}
            value={searchInput}
          />
          <button className="btn" type="submit">
            <img src="../images/searchicon.png" alt="icon" />
          </button>
          <div className="setsearchdata">
            <ul>
              {searchedData.map((results) => (
                <li key={results.id} className="postsearch" onClick={() => postClick(results.id)}>
                  {results.posts}
                  <i className="fas fa-arrow-right"></i>
                </li>
              ))}
            </ul>
          </div>
        </form>
        <Link to="/createpost" className="hpl">
          <img src="../images/iconS2.png" alt="icon" align="icon" /> <span>Start Discussion</span>
        </Link>
      </div>
      <div className="addpost">
        <h3>Add post</h3>
        <div className="addhead">
          <div className="loadingicon" id="loadingicon">
            <img src="../../images/loading.gif" alt="Loading" />
          </div>
          <form className="d-flex" onSubmit={handleSubmit}>
            <Link to="/userprofile">
              <span className="userimg">
                <img src={userImage} align="icon" alt="User" />
              </span>
            </Link>
            <textarea
              name="post"
              className="form-control"
              placeholder="Type What are you Thinking..."
              id="post"
              value={enteredText}
              onChange={updateState}
            ></textarea>
            <div className="text-danger">{/* Display errors here */}</div>
            <div className="maindivkap">
              <div className="row">
                {imagesPreviewUrls.map((imagePreviewUrl, i) => (
                  <div className="col-4 col-sm-3 col-lg-2" key={i} onClick={() => removeImage(i)}>
                    <div className="imgg">
                      <i className="fa fa-times"></i>
                      <img className="upim w-100" alt="previewImg" src={`https://domaintobesocial.com/domaintobe/assets/allimages/`+imagePreviewUrl} />
                    </div>
                  </div>
                ))}
                {videosPreviewUrls.map((videoPreviewUrl, i) => (
                  <div className="col-sm-3 col-lg-2" key={i} onClick={() => removeVideo(i)}>
                    <div className="imgg">
                      <i className="fa fa-times"></i>
                      <video width="320" height="240" controls>
                        <source src={videoPreviewUrl} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <ul>
              <li>
                <input type="file" name="" onChange={_handleImageChange} multiple accept="image/*" />
                <img src="../images/addicon1.png" align="icon" alt="Add Image" />
              </li>
              <li>
                <input type="file" name="" onChange={_handleVideoChange} multiple accept="video/*" />
                <img src="../images/addicon2.png" align="icon" alt="Add Video" />
              </li>
              <li className="dropdown" data-toggle="modal" data-target="#exampleModalHelp">
                <span>
                  <img src="../images/addicon3.png" align="icon" alt="Dropdown" />
                </span>
              </li>
              <li className="dropdown">
                <span className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <img src="../images/addicon4.png" align="icon" alt="Dropdown" />
                </span>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start">
                  <input
                    type="text"
                    className="form-control linkurl"
                    name="url"
                    placeholder="Add Url"
                    onChange={handleChangelinkUrl}
                    id="url"
                    value={linkUrl}
                  />
                </div>
              </li>
              <li onClick={onClick}>
                <img src="../images/addicon5.png" align="icon" alt="Add Icon 5" />
              </li>
            </ul>
            <button className="btn" type="submit">
              Update
            </button>
          </form>
        </div>
      </div>
      </div>
      </>
    )
}

export default EditDiscussion