import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function Followers() {
    const [followers, setFollowers] = useState([]);
    let currentLogin = JSON.parse(window.localStorage.getItem("user"));
    const [filterValue, setFilterValue] = useState(null);
    const [userImage, setUserImage] = useState("/images/blank.png");
    const [blockdata, setBlockdata] = useState([]);  
    const fetchFollowers = async () => {
        try {
            const response7 = await axios.get(
              "https://domaintobesocial.com/domaintobe/blockget",
              {
                params: {
                  userid: currentLogin.value,
                },
              }
            );
    
            if (response7 && response7.data && response7.data.message) {
              setBlockdata(response7.data.message);
            } else {
              console.log("No data or unexpected data format in the response.");
            }
          } catch (err) {
            setBlockdata([]);
          }
        try {
            const formData = new FormData();
            formData.append('id', currentLogin.value);

            const response1 = await axios.post('https://domaintobesocial.com/domaintobe/getfollowers', formData);

            if (response1.data.status === 'data') {
                setFollowers(response1.data.message);
            } else {
                alert(response1.data.message);
            }
        } catch (error) {
            console.error('Error fetching followers:', error.message);
        }
    };

    useEffect(() => {
        fetchFollowers();
    }, [currentLogin.value]);

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value === '' ? null : value);
    };

    const filteredData = filterValue
        ? followers.filter((item) => item.name.toLowerCase().includes(filterValue.toLowerCase()))
        : followers;

    const unfollow = async (id) => {
        try {
            const formData = new FormData();
            formData.append('id', id);

            const response = await axios.post('https://domaintobesocial.com/domaintobe/unfollow', formData);

            console.log(response);

            if (response.data.message === 'Success') {
                // Refetch followers after successful unfollow
                fetchFollowers();
                alert(response.data.message);
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error unfollowing:', error.message);
        }
    };

    return (
         <div className="in_center in_center_discussion">
        <div className="head pr-0">
            <form className="d-flex w-100">
                <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={filterValue || ''}
                    onChange={handleFilterChange}
                />
                <button className="btn" type="submit">
                    <img src="images/searchicon.png" alt="icon" />
                </button>
            </form>
            <div className="my_followers">
                <div className="row">
                    {followers.length > 0 ? filteredData.map((resultfo, i) => (<>
                        {blockdata &&
          blockdata.filter(
            (item) =>
              (
                (item.friendid === resultfo.friendid && item.userid === resultfo.userid) ||
                (item.friendid === resultfo.userid && item.userid === resultfo.friendid)
              ) &&
              item.status === "1"
             
          ).length > 0 ? null : (
            <>
                        <div className="col-lg-6 col-xl-4" key={resultfo.id}>
                            <div className="test">
                            <Link
                        to={{ pathname: "/viewprofile/" + resultfo.name }}
                      >  <span className="userimg">
                                    <img src={resultfo.image ? resultfo.image : userImage} align="icon" alt={resultfo.name} />
                                </span>
                                <h5>{resultfo.name}</h5></Link>
                                <ul className="followmessage flowmsg">
                                    <li><a onClick={() => unfollow(resultfo.id)}>Unfollow</a></li>
                                    <li><Link to="/messages" className="mg" href="">Message</Link></li>
                                </ul>
                            </div>
                        </div>
                        </>
          )}</> )) :
                        <div className="norecord">
                            <img src="/images/nodata.png" alt="No data" />
                        </div>
                    }
                </div>
            </div>
        </div>
        </div>
    );
}

export default Followers;
