import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Stories from 'react-insta-stories';

const UserStories = (update1) => {
  const [selectedUserStories, setSelectedUserStories] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0); 
  const curentlogin = JSON.parse(window.localStorage.getItem("user"));
  const [update, setUpdate] = useState(0);
  const [dimensions, setDimensions] = useState({
    width: 432,
    height: 768,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDimensions({ width: window.innerWidth * 0.8, height: window.innerHeight * 0.8 });
      } else {
        setDimensions({ width: 420, height: 600 });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    
    axios
      .get('https://domaintobesocial.com/domaintobe/api/stories')
      .then((res) => {
        const allUsers = res.data.message;

        // Find the current logged-in user's stories and place them at the beginning
        const currentUserIndex = allUsers.findIndex(user => user.userid===curentlogin.value );

        if (currentUserIndex !== -1) {
          const [currentUser] = allUsers.splice(currentUserIndex, 1);
          allUsers.unshift(currentUser); // Move the current user to the first position
        }

        setUsers(allUsers);
      })
      .catch((error) => console.log(error));
  }, [update, update1.update1.update]);

  const handleClick = (userStories) => {
    setSelectedUserStories(userStories);
    setIsModalOpen(true);
    setCurrentStoryIndex(0); // Reset to the first story
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteStory = () => {
  
    if (selectedUserStories) {
      const storyId = selectedUserStories[currentStoryIndex]?.id;
console.log(selectedUserStories)
console.log(currentStoryIndex)
      setIsModalOpen(false);
      setSelectedUserStories(null);

      if (storyId) {
        axios
          .post(`https://domaintobesocial.com/domaintobe/api/stories/delete/${currentStoryIndex.id}`)
          .then((res) => {
            setUpdate(update + 1); // Refresh the stories after deletion
          })
          .catch((error) => console.log(error));
      } else {
        console.log("Story ID not found");
      }
    }
  };

  return (
    <>

      <div className='story-sec' style={{ display: 'flex' }}>
        {users.map((user, index) => (
          <div className='inner-story'
            key={index}
            onClick={() => handleClick(user.stories)}
            style={{
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            <img className='top-img' src={user.userimage} />
            <img
              src={user.stories[0].url}
              alt={user.name}
              style={{ width: '100%', height: '100%', objectFit: 'cover',  }}
            />
            <div className='story-text'>
            <p className='story-para'>{user.name}</p>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal-story"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            zIndex: 1000,
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          }}
        >
          <Stories
            stories={selectedUserStories}
            defaultInterval={3000}
            width={dimensions.width}
            height={dimensions.height}
            onStoryStart={(story, index) => setCurrentStoryIndex(index)}
            onAllStoriesEnd={closeModal}
          />
          <button
            onClick={closeModal}
            style={{
              zIndex: '9999',
              position: 'absolute',
              top: '40px',
              right: '30px',
              background: 'transparent',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={14}
              width="15.75"
              viewBox="0 0 384 512"
            >
              <path
                fill="#ffffff"
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </button>
          {currentStoryIndex.userid==curentlogin.value? <svg
            xmlns="http://www.w3.org/2000/svg"
            height={20}
            width="15.75"
            viewBox="0 0 448 512"
            onClick={deleteStory}
            style={{
              zIndex: '9999',
              position: 'absolute',
              top: '80px',
              right: '35px',
              background: 'transparent',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <path
              fill="#74C0FC"
              d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"
            />
          </svg>:''}
         
        </div>
      )}

      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={closeModal}
        ></div>
      )}
    </>
  );
};

export default UserStories;
