import React, { useEffect, useState } from "react";
import axios from "axios";

function Viewblog(props) {
  const [blogposts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.match && props.match.params && props.match.params.id) {
          const formData = new FormData();
          formData.append("id", props.match.params.id);
          const response = await axios.post(
            "https://domaintobesocial.com/domaintobe/viewsingleblog",
            formData
          );
          setBlogPosts(response.data.message);
        }
      } catch (error) {
        alert("Invalid Login1");
      }
    };

    // fetchData();
  }, [props.match]);

  useEffect(() => {
    const stringValue = window.localStorage.getItem("user");
    if (stringValue !== null) {
      const value = JSON.parse(stringValue);
      const expirationDate = new Date(value.expirationDate);

      if (expirationDate <= new Date()) {
        window.localStorage.removeItem("user");
        window.location = "/";
      }
    } else {
      window.localStorage.removeItem("user");
      window.location = "/";
    }
  }, []);

  const likePost = async () => {
    try {
      const userid = JSON.parse(window.localStorage.getItem("user"));
      if (props.match && props.match.params && props.match.params.id) {
        const formData = new FormData();
        formData.append("id", props.match.params.id);
        formData.append("userid", userid.value);
        const response = await axios.post(
          "https://domaintobesocial.com/domaintobe/bloglike",
          formData
        );

        if (response.data.message === "success") {
          // fetchData();
        } else {
          alert(response.data.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <div className="in_center in_center_discussion">
        <div className="head" style={{ display: "none" }}>
          <form className="d-flex">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn" type="submit">
              <img src="/images/searchicon.png" alt="icon" />{" "}
            </button>
          </form>
        </div>

        {blogposts.length > 0 ? (
          <div className="listusr discussion help">
            {blogposts.map((result) => {
              return (
                <div className="test">
                  <div className="categoryhd">
                    <h3>Blog</h3>
                  </div>
                  <div className="asuser">
                    <h5>{result.title}</h5>
                    <p>{result.created} Ago</p>
                  </div>
                  <p>{result.description}</p>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="testin mt-0 h-auto">
                        <img className="w-100" src={result.image} alt="ion" />
                      </div>
                    </div>
                  </div>
                  <ul className="likecomment">
                    <li onClick={likePost}>
                      <img src="/images/like.png" alt="ion" />
                      {result.likes}
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="loadingicon"
            id="loadingicon"
            style={{ display: "block" }}
          >
            <img src="/images/loading.gif" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Viewblog;
