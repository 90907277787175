import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Zoom from "react-medium-image-zoom";

const Posts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  function deletepost(id) {
    var obj = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();

    formData.append("userId", obj.value);
    formData.append("postId", id);
    axios
      .post("https://domaintobesocial.com/domaintobe/deletepost", formData)
      .then((res) => {
        swal({
          title: "Success!",
          text: "delete successfully done",
          icon: "success",
          confirmButtonText: "Ok",
        });
        setupdate(update + 1);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors as needed
      });
  }
  function newsfeed(id) {
    var obj = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();

    formData.append("userId", obj.value);
    formData.append("postId", id);
    axios
      .post("https://domaintobesocial.com/domaintobe/deletenews", formData)
      .then((res) => {
        swal({
          title: "Success!",
          text: "delete successfully done",
          icon: "success",
          confirmButtonText: "Ok",
        });
        setupdate(update + 1);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors as needed
      });
  }
  const [userImage, setUserImage] = useState("/images/blank.png");
  const [postsData, setPostsData] = useState([]);
  const [helpsData, setHelpsData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [update, setupdate] = useState(0);
  const [filterValueDiscussion, setFilterValueDiscussion] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  let curentlogin = JSON.parse(window.localStorage.getItem("user"));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("id", curentlogin.value);

        const response = await axios.post(
          "https://domaintobesocial.com/domaintobe/getusersallposts",
          formData
        );

        if (response.data.status === "data") {
     
          setPostsData(response.data.message.newsfeed);
          setHelpsData(response.data.message.helps);
          setPosts(response.data.message.posts);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [update]);
  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value === "" ? null : value);
  };
  const handleFilterChangeDiscussion = (event) => {
    const value = event.target.value;
    setFilterValueDiscussion(value === "" ? null : value);
  };
  const filteredData = filterValue
  ? postsData?.filter((item) =>
      item?.posts?.toLowerCase().includes(filterValue.toLowerCase())
    )
  : postsData || []; // Ensure postsData is at least an empty array

const totalPages = Math.ceil((filteredData?.length || 0) / postsPerPage);


    // Get the posts for the current page
    const currentPosts = filteredData.slice(
      (currentPage - 1) * postsPerPage,
      currentPage * postsPerPage
    );
  
  const filteredDataDiscussion = filterValueDiscussion
    ? posts.filter((item) =>
        item.title.toLowerCase().includes(filterValueDiscussion.toLowerCase())
      )
    : posts;


    let commentpromission=(id)=>{

axios.post(`https://domaintobesocial.com/domaintobe/comments/visible/${id}`)
.then(res=>{console.log(res)
  setupdate(update + 1);
})
.catch(error=>console.log(error))
    }
    let newsfeedpromission=(id)=>{

axios.post(`https://domaintobesocial.com/domaintobe/newsfeed/visible/${id}`)
.then(res=>{console.log(res)
  setupdate(update + 1);
})
.catch(error=>console.log(error))
    }
  return (
    <>
      {" "}
      <h3>Posts</h3>
      <div className="listusr help Postall">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              News Feeds
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Discussion
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#contact"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              Help
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="head pr-0">
              <form className="d-flex w-100">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={filterValue || ""}
                  onChange={handleFilterChange}
                />
                <button className="btn usersearch" type="button">
                  <img src="images/searchicon.png" alt="icon" />{" "}
                </button>
              </form>
            </div>
            <div className="linkedin-activity-tab">
              <div className="tab-news">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                    >
                      Post
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-2"
                      role="tab"
                    >
                      Comments
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-3"
                      role="tab"
                    >
                      Video
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-4"
                      role="tab"
                    >
                      Images
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                {/* <div className="tab-pane active" id="tabs-1" role="tabpanel">
                  {filteredData?.map((resultp) => {
                    return (
                      <div className="linkdin-post-inner">
                        <div className="posted-time">
                          <p>
                            <span>{resultp.created} Ago</span>
                          </p>
                        </div>
                        <div className="posted-name-contant">
                          <div className="linkdin-post-img">
                            {resultp.images && resultp.images !== "" ? (
                              <img src={resultp.images} />
                            ) : (
                              <img src="/images/No-Image.png" />
                            )}
                          </div>
                          <div className="linkd-content-inner">
                            <p>
                              <span>{resultp.posts}</span>{" "}
                            </p>
                          </div>
                        </div>
                        <div className="see-more">
                          <Link to={"/editPost/" + resultp.id} className="m-1 fw-bolder">See More</Link>
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => newsfeed(resultp.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div> */}
                 <div className="tab-pane active" id="tabs-1" role="tabpanel">
      {currentPosts.map((resultp,i) => (
        <div className="linkdin-post-inner" key={i}>
          <div className="posted-time">
            <p>
              <span>{resultp.created} Ago</span>
              <span className="float-right"> Hide Post : <input type="checkbox" onChange={()=>newsfeedpromission(resultp.id)}  checked={resultp.visibilitypost==0?true:false}/></span>
            </p>
          </div>
          <div className="posted-name-contant">
            <div className="linkdin-post-img">
              {resultp.images && resultp.images !== "" ? (
                <img src={resultp.images} alt="Post Image" />
              ) : (
                <img src="/images/No-Image.png" alt="No Image Available" />
              )}
            </div>
            <div className="linkd-content-inner">
              <p>
                <span>{resultp.posts}</span>
                
              </p>
            </div>
          </div>
          <div className="see-more">
            <Link to={"/editPost/" + resultp.id} className="m-1 fw-bolder">
              Edit Post
            </Link>
            <button
              className="btn btn-outline-danger"
              onClick={() => newsfeed(resultp.id)}
            >
              Delete
            </button>
          </div>
        </div>
      ))}

      {/* Pagination Controls */}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`btn ${index + 1 === currentPage ? 'btn-primary' : 'btn-outline-primary'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
                <div className="tab-pane" id="tabs-2" role="tabpanel">
                  {filteredData?.map((resultp) => {
                    return (
                      <>
                        {" "}
                        {resultp.comments && (
                          <div className="linkdin-post-inner" key={resultp.id}>
                            <div className="posted-time">
                              <p>
                                <span>{resultp.posts}</span>
                                <span className="float-right"> Hide All Comment : <input type="checkbox" onChange={()=>commentpromission(resultp.id)}  checked={resultp.visibility==0?true:false}/></span>
                              </p>
                            </div>
                            <div className="posted-name-contant">
                              <div className="linkd-content-inner">
                                <p>
                                  {" "}
                                  <span>comments:</span> {resultp.comments}{" "}
                                </p>
                              </div>
                            </div>
                           
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
                <div className="tab-pane" id="tabs-3" role="tabpanel">
                  <div className="linkdin-post-inner">
                    <div className="posted-video-info">
                      {filteredData?.map((resultp) => {
                        return (
                          <>
                            {" "}
                            {resultp.videos && (
                              <div className="posted-video-inner" key={resultp.id}>
                                <video width="320" height="240" controls>
                                  <source
                                    src={resultp.videos}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            )}
                            {resultp.url &&
                            resultp.url.split("/")[2] == "youtu.be" ? (
                              <div className="posted-video-inner">
                                <iframe
                                  width="100%"
                                  height="400px"
                                  src={
                                    "https://www.youtube.com/embed/" +
                                    resultp.url.split("/")[3]
                                  }
                                  title="YouTube video player"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            ) : resultp.url.split("/")[2] ==
                                "www.youtube.com" &&
                              resultp.url.split("/")[3] == "live" ? (
                              <div className="posted-video-inner">
                                <iframe
                                  width="100%"
                                  height="400px"
                                  src={
                                    "https://www.youtube.com/embed/" +
                                    resultp.url.split("/")[4]
                                  }
                                  title="YouTube video player"
                                  allow="autoplay"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            ) : resultp.url.split("/")[2] == "rumble.com" ? (
                              <div className="posted-video-inner">
                                <iframe
                                  width="100%"
                                  height="400px"
                                  src={
                                    "https://rumble.com/embed/" +
                                    resultp.url.split("/")[4]
                                  }
                                  title="YouTube video player"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tabs-4" role="tabpanel">
                  <div className="linkdin-post-inner">
                    <div className="posted-video-info">
                      {filteredData?.map((resultp) => {
                        return (
                          <>
                            {" "}
                            {resultp.images && (
                              <div className="posted-video-inner">
                                {resultp.images && resultp.images !== "" ? (
                                  <Zoom>
                                  <img src={resultp.images} />
                                  </Zoom>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="usersec">

<div className="row">

{filteredData?.map((resultp) => {

return (
<div className="col-sm-6 col-lg-4 mb-2">
<div className="singleposttest">
<div className="asuser mb-0">
<span className="userimg"><img src={resultp.userimage?resultp.userimage:userImage} align="icon"/></span>
<h5>{resultp.username}
</h5>
<p>{resultp.created} Ago</p>
</div>
<div className="contants">

{resultp.images && resultp.images!=='' ? <div className="img"><img className="w-100" src={resultp.images}/></div> :<img className="w-100" src='/images/No-Image.png'/>}
<p>{resultp.posts}</p>
<i className="fa fa-thumbs-up g-pos-rel g-top-1 g-mr-3"></i>{resultp.likes}


<Link to={'/editPost/'+resultp.id}>Edit post <i className="fas fa-long-arrow-alt-right"></i></Link>
</div>
</div>
</div>
)
})}

</div>
</div> */}
          </div>
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="head pr-0">
              <form className="d-flex w-100">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={filterValueDiscussion || ""}
                  onChange={handleFilterChangeDiscussion}
                />
                <button className="btn usersearch" type="button">
                  <img src="images/searchicon.png" alt="icon" />{" "}
                </button>
              </form>
            </div>
            <div className="linkedin-activity-tab">
              <div className="tab-news">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#dtabs-1"
                      role="tab"
                    >
                      Post
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#dtabs-2"
                      role="tab"
                    >
                      Comments
                    </a>
                  </li>
                  {/* <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#dtabs-3" role="tab">Video</a>
                    </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#dtabs-4"
                      role="tab"
                    >
                      Images
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                <div className="tab-pane active" id="dtabs-1" role="tabpanel">
                  {filteredDataDiscussion?.map((resultp) => {
                    return (
                      <div className="linkdin-post-inner">
                        <div className="posted-time">
                          <p>
                            <span>{resultp.created}</span> Ago
                          </p>
                        </div>
                        <div className="posted-name-contant">
                          <div className="linkdin-post-img">
                            {resultp.images && resultp.images !== "" ? (
                              <img
                                src={
                                  "https://domaintobesocial.com/domaintobe/assets/allimages/" +
                                  resultp.images
                                }
                              />
                            ) : (
                              <img src="/images/No-Image.png" />
                            )}
                          </div>
                          <div className="linkd-content-inner">
                            <p>
                              <span>{resultp.title}</span>
                              <br />
                              <span> category :</span> {resultp.catname}
                              <br />
                              <span> Subcategory :</span> {resultp.subcat}
                            </p>
                          </div>
                        </div>
                        <div className="see-more">
                          <Link to={"/editDiscussion/" + resultp.id} className="m-1">
                            See More
                          </Link>
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => deletepost(resultp.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="tab-pane" id="dtabs-2" role="tabpanel">
                  {filteredDataDiscussion?.map((resultp) => {
               
                    return (
                      <>
                        {" "}
                        <div className="linkdin-post-inner">
                          <div className="posted-time">
                            <p>
                              <span>{resultp.title}</span>
                            </p>
                          </div>
                          <div className="posted-name-contant">
                            <div className="linkd-content-inner">
                              <p>
                                <span> category :</span> {resultp.catname}
                                <br />
                                <span> Subcategory :</span> {resultp.subcat}{" "}
                                <br />
                                <span> comment :</span> {resultp.comment}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {/* <div className="tab-pane" id="dtabs-3" role="tabpanel">
                      <div className="linkdin-post-inner">                          
                          <div className="posted-video-info">   
                          {filteredData?.map((resultp) => {

return ( <>  {resultp.videos &&<div className="posted-video-inner">
                             <video width="320" height="240" controls>
                                <source
                                  src={resultp.videos}
                                  type="video/mp4"
                                />
                              </video>
                              </div>  }
                          
                            {resultp.url &&
                        resultp.url.split("/")[2] == "youtu.be" ? (
                         <div className="posted-video-inner">
                            <iframe
                              width="100%"
                              height="400px"
                              src={
                                "https://www.youtube.com/embed/" +
                                resultp.url.split("/")[3]
                              }
                              title="YouTube video player"
                              allowFullScreen
                            ></iframe>
                          </div>
                        ) : resultp.url.split("/")[2] == "www.youtube.com" &&
                          resultp.url.split("/")[3] == "live" ? (
                            <div className="posted-video-inner">
                            <iframe
                              width="100%"
                              height="400px"
                              src={
                                "https://www.youtube.com/embed/" +
                                resultp.url.split("/")[4]
                              }
                              title="YouTube video player"
                              allow="autoplay"
                              allowFullScreen
                            ></iframe>
                          </div>
                        ) : resultp.url.split("/")[2] == "rumble.com" ? ( <div className="posted-video-inner">
                          <iframe
                            width="100%"
                            height="400px"
                            src={
                              "https://rumble.com/embed/" +
                              resultp.url.split("/")[4]
                            }
                            title="YouTube video player"
                            allowFullScreen
                          ></iframe>
                          </div>
                        ) : (
                          ""
                        )} 
                            
                            </>)})}
                                                                                 
                          </div>                           
                        </div>
                    </div> */}
                <div className="tab-pane" id="dtabs-4" role="tabpanel">
                  <div className="linkdin-post-inner">
                    <div className="posted-video-info">
                      {filteredDataDiscussion?.map((resultp) => {
                        return (
                          <>
                            {" "}
                            {resultp.images && (
                              <div className="posted-video-inner">
                                {resultp.images && resultp.images !== "" ? (
                                 <Zoom>  <img
                                    src={
                                      "https://domaintobesocial.com/domaintobe/assets/allimages/" +
                                      resultp.images
                                    }
                                  /></Zoom>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
{filteredDataDiscussion?.map((resultp) => {
//console.log(posts) 

return (
<div className="col-sm-6 col-lg-4  mb-3">

<div className="singleposttest">
<div className="asuser mb-0">
<span className="userimg"><img src={resultp.image?resultp.image:userImage} align="icon"/></span>
<h5>{resultp.name}
</h5>
<p>{resultp.created} Ago</p>

</div>
<p>category : {resultp.catname} </p>
<p>Subcategory : {resultp.subcat} </p>
<div className="contants">
{resultp.images && resultp.image!=='' ? <div className="img"><img className="w-100" src={"https://domaintobesocial.com/domaintobe/assets/allimages/"+resultp.images}/></div> :<img className="w-100" src='/images/No-Image.png'/>}
<p>{resultp.description}</p>
<Link to={'/editPost/'+resultp.id}>Edit post <i className="fas fa-long-arrow-alt-right"></i></Link>
</div>
</div>
</div>
)
})}
</div> */}
          </div>
          <div
            className="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div className="row">
              {helpsData?.map((resultp) => {
       
                return (
                  <div className="col-sm-12 col-lg-12  mb-3">
                    <div className="help-part">
                      <div className="asuser mb-0 pl-0">
                      <div className=" main-contant d-flex">
                        {resultp.image && resultp.image !== "" ? (
                          <div className="img">
                            <img className="w-100 h-50" src={resultp.image} />
                          </div>
                        ) : (
                          <img className="w-100" src="/images/No-Image.png" />
                        )}
                    
                    <div className="asuser pl-3 newsarea">
                         
                    <p className="text-dark ">{resultp.description}</p>
                        <h5 >{resultp.username}</h5>
                        <p>{resultp.created} Ago</p>
                        <p>category : {resultp.profession_name} </p>
                        <p>subcategory : {resultp.subprofession_name} </p>
                      </div>
                      </div>
                   
                      </div>
                   
                      <div className="d-flex justify-content-between align-items-center pt-3">
             
                        <Link to={"/editHelp/" + resultp.id}>
                      Edit Post  {" "}
                          <i className="fa fa-solid fa-edit"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Posts;
