import axios from 'axios';
import { getDatabase, onValue, push, ref, remove, set, update } from 'firebase/database';
import React, { useEffect, useRef, useState } from 'react'
import appvalue from '../../firebase';
import swal from 'sweetalert';
import $ from 'jquery'
import { Link, useLocation } from 'react-router-dom';
import Zoom from "react-medium-image-zoom";
const Messages = () => {
    const [filterValue, setFilterValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [blockdata, setBlockdata] = useState([]);  
    const [chatId, setChatId] = useState(null);
    const [chatEmail, setchatEmail] = useState(null);
    const [chatUsername, setChatUsername] = useState('');
    const [chatUserImage, setChatUserImage] = useState('');
    const [chatuserroles, setChatuserroles] = useState('');
    const [friendsData, setFriendsData] = useState([]);
    const [helprequestdata, sethelprequestdata] = useState(true);
    const [chatingdata, setChatingData] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
    const curentlogin = JSON.parse(window.localStorage.getItem('user'));
  const [files, setFiles] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [input, setInput] = useState({ message: '' });
  const [errors, setErrors] = useState({});
  const [currentusername, setCurrentUsername] = useState('');
  const [currentuserroles, setCurrentuserroles] = useState('');
  
  const [notificationCount, setNotificationCount] = useState(0);

  const [userImage, setUserImage] = useState('/images/blank.png');
  const [currentuserImage, setCurrentuserImage] = useState('/images/blank.png');
  const [formFilled, setFormFilled] = useState('');
  const [isVipRole, setIsVipRole] = useState(false);

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queries = Object.fromEntries(queryParams.entries());
    const formData = new FormData();
    formData.append('id', queries.uid);
    formData.append('user', curentlogin.value);
  axios.post('https://domaintobesocial.com/domaintobe/getuserprofilename', formData)
  .then(res=>{
    let data = res.data.message;
  
    if (data.name !== "undefined") {
      selectUser(0, data.id, data.name, data.image?data.image:"/images/blank.png")
    }
   
  })
  .catch(err=>console.log(err.response.data.message));

    // const filtered =  friendsData.filter((item) => item.id==)
   


  },[location]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response7 = await axios.get(
          "https://domaintobesocial.com/domaintobe/blockget",
          {
            params: {
              userid: curentlogin.value,
            },
          }
        );

        if (response7 && response7.data && response7.data.message) {
          setBlockdata(response7.data.message);
        } else {
          console.log("No data or unexpected data format in the response.");
        }
      } catch (err) {
        setBlockdata([]);
      }
      try {
        // Count notifications
        const formDataCount = new FormData();
        formDataCount.append('id', curentlogin.value);
        formDataCount.append('user', curentlogin.value);
        const responseCount = await axios.post('https://domaintobesocial.com/domaintobe/countnotifications', formDataCount);
        setNotificationCount(responseCount.data.message);

        // Get user profile
        const formDataProfile = new FormData();
        formDataProfile.append('id', curentlogin.value);
        formDataProfile.append('user', curentlogin.value);
        const responseProfile = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formDataProfile);

        setCurrentUsername(responseProfile.data.message.name);
        setCurrentuserroles(responseProfile.data.message.userroles);

        let updatedInput = { ...input };
        updatedInput.name = responseProfile.data.message.name;
        updatedInput.uid = responseProfile.data.message.id;
        setInput(updatedInput);

        setFormFilled(responseProfile.data.message.formfilled);

        if (responseProfile.data.message.roles === 'vip') {
          setIsVipRole(true);
        } else {
          setIsVipRole(false);
        }

        setUserImage(responseProfile.data.message.image || '/images/blank.png');
        setCurrentuserImage(responseProfile.data.message.image || '/images/blank.png');
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, []);



  const db = getDatabase(appvalue);
  const validate = () => {
    
    const  message  = inputMessage;

    const errorsCopy = { ...errors };
    const messageError = validateMessage(message);
    if (messageError) {
      errorsCopy.message = messageError;
    } else {
      delete errorsCopy.message;
    }

    setErrors(errorsCopy);

    return !messageError;
  };
  function validateMessage(message) {
    if (!message) {
      return 'Please add a message.';
    }
    return '';
  }
  const handleChange = (e) => {
      const textarea = e.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      setInputMessage(e.target.value);
    
  };
  const handleImageChange = (e) => {
    e.preventDefault();
    let newFiles = Array.from(e.target.files);

    newFiles.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        const filesize = Math.round(file.size / 1024);
        if (filesize > 2048) {
          swal("!Oops", "File too large, please select a file less than 2mb", "error");
        } else {
          setFiles((prevFiles) => [...prevFiles, file]);
          setImagesPreviewUrls((prevUrls) => [...prevUrls, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const scrollRef = useRef(null);
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const fetchData = () => {
      onValue(ref(db, `lastchat/${curentlogin.value}`), snapshot => {
        const check = snapshot.val();
        if (check === null) {
          setChatUserImage('./images/blank.png');
        } else {
          setChatId(check.uid);
          setChatUsername(check.name);
          setChatUserImage(check.image);

          const sender = `${curentlogin.value}_${check.uid}`;
          onValue(ref(db, `chat/${sender}`), snapshot => {
            let chatingdatas = [];
            snapshot.forEach(snap => {
              chatingdatas.push(snap.val());
            });
            setChatingData(chatingdatas);
            scrollToBottom();
            $(".dddd").stop().animate({ scrollTop: $(".dddd")[0].scrollHeight}, 1000);
          });
        }
      });
    };
    const queryParams = new URLSearchParams(window.location.search);
   if(queryParams.get("id") != null){
    setChatId(queryParams.get("id"));
    setChatUsername(queryParams.get("n"));
    setChatUserImage(queryParams.get("i"));
    // selectUser(null,queryParams.get("id"),queryParams.get("n"),queryParams.get("i"))
  }
    else{
      fetchData();
    }
   
  }, [curentlogin.value]);
  const submitChat = async (e) => {
    e.preventDefault();
    const sender = `${curentlogin.value}_${chatId}`;
    const receiver = `${chatId}_${curentlogin.value}`;
    const time = new Date().toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
    const senderRef = ref(db, 'chat/' + sender);
    const receiverRef = ref(db, 'chat/' + receiver);
    const chatwithRef1 = ref(db, `chatwith/${curentlogin.value}/${chatId}`);
    const chatwithRef2 = ref(db, `chatwith/${chatId}/${curentlogin.value}`);
    const lastchatRef = ref(db, `lastchat/${curentlogin.value}`);
  let cid = curentlogin.value
  const formData2 = new FormData();

  // Append data to FormData
  formData2.append('cid', cid);
  formData2.append('chatId', chatId);
  formData2.append('inputMessage', inputMessage);

  try {
    // Make a POST request to the API
    const response = await axios.post('https://domaintobesocial.com/domaintobe/api/send-message',formData2);

    // Check if the response was successful
    if (response.data.status === 'success') {
        console.log(response.data.message);
    } else {
      console.log('Failed to send message.');
    }
} catch (error) {
    console.error('Error sending message:', error);
    console.log('An error occurred while sending the message.');
}
    if (imagesPreviewUrls.length !== 0) {
      const formData = new FormData();
      files.forEach((file) => formData.append('files[]', file));
      formData.append('tagged', JSON.stringify(checkedItems));
    
      try {
        const response = await axios.post('https://domaintobesocial.com/domaintobe/chatimage', formData);
        const imageMessage = response.data.message || '';
  
        // Send the image message
        push(senderRef, {
          read: 'y',
          side: 'right',
          msg: imageMessage, // Only image message
          image: chatUserImage,
          time: time,
        });
  
        push(receiverRef, {
          read: 'n',
          side: 'left',
          msg: imageMessage, // Only image message
          image: chatUserImage,
          time: time,
        });
  
        // Clear images and files after sending
        setImagesPreviewUrls([]);
        setFiles([]);
      } catch (error) {
        console.log(error.message);
      }
    }
  
    // Send text message if valid
    if (validate()) {
      // Push text message
      push(senderRef, {
        read: 'y',
        side: 'right',
        msg: inputMessage,
        image: currentuserImage,
        time: time,
      });
  
      push(receiverRef, {
        read: 'n',
        side: 'left',
        msg: inputMessage,
        image: currentuserImage,
        time: time,
      });
  
      // Update chat with info
      set(chatwithRef1, {
        uid: chatId,
        name: chatUsername,
        image: chatUserImage,
        msg: inputMessage,
        time: time,
      });
  
      set(chatwithRef2, {
        uid: curentlogin.value,
        name: currentusername,
        image: currentuserImage,
        msg: inputMessage,
        time: time,
        userroles:currentuserroles
      });
  
      set(lastchatRef, {
        uid: chatId,
        name: chatUsername,
        image: chatUserImage,
        msg: inputMessage,
        time: time,
      });
  
      // Fetch updated chat data
      onValue(senderRef, (snapshot) => {
        const chatingdatas = [];
        snapshot.forEach((snap) => {
          chatingdatas.push(snap.val());
        });
        setChatingData(chatingdatas);
        $(".dddd").stop().animate({ scrollTop: $(".dddd")[0].scrollHeight }, 1000);
      });
  
      // Reset input field
      setInputMessage('');
    }
  };
  
    
    useEffect(() => {
        // Apply filtering logic when filterValue or friendsData changes
        const filtered = filterValue
          ? friendsData.filter((item) => item.name.toLowerCase().includes(filterValue.toLowerCase()))
          : friendsData;
    console.log(filtered)
        setFilteredData(filtered);
      }, [filterValue, friendsData]);

      
  useEffect(() => {
    if (chatId && curentlogin) {
        // Update the 'read' field in the database
        onValue(ref(db, `chat/${chatId}_${curentlogin.value}`), (snapshot) => {
          if (snapshot.exists()) {
            const updates = {};
            snapshot.forEach((childSnapshot) => {
              updates[`${chatId}_${curentlogin.value}/${childSnapshot.key}/read`] = 'n';
            });
            update(ref(db), updates);
          }
        });
  
        const sender = `${curentlogin.value}_${chatId}`;
  
        onValue(ref(db, `chat/${sender}`), (snapshot) => {
          let chatingDatas = [];
          snapshot.forEach((childSnapshot) => {
            chatingDatas.push(childSnapshot.val());
          });
          setChatingData(chatingDatas);
          // $(".dddd").stop().animate({ scrollTop: $(".dddd")[0].scrollHeight}, 1000);
        });
      }
  }, [chatId]);
 
    const selectUser = (i, friendId, name, image,role,email) => {
      axios.get(`https://domaintobesocial.com/domaintobe/api/helprequestchatdata/${friendId}`)
      .then((response) => {response.data.length>0 &&  sethelprequestdata(response.data[0].status==='1'?true:false)})
      .catch((error) => {});
      setChatId(friendId);
      setChatUsername(name);
      setChatUserImage(image);
      setChatuserroles(role);
      setchatEmail(email);
    }; 

      
  let helprequest=(userid)=>{
    
    axios.post(`https://domaintobesocial.com/domaintobe/api/helprequestchat`,{userid:curentlogin.value,request_user_id:chatId})
    .then(response => {sethelprequestdata(true)}).catch({});
    
      }
     // You may need to initialize this state based on your use case
    const selectUserfordelete = (i, friendId, name, image) => {

        setChatId(friendId);
        setChatUsername(name);
        setChatUserImage(image);

        const sender = curentlogin.value + '_' + chatId;
  const receiver = chatId + '_' + curentlogin.value;

  // Remove data for sender
  remove(ref(db, `chat/${sender}`))
    .then(() => {
      console.log('Data for sender deleted successfully');
    })
    .catch((error) => {
      console.error('Error removing data for sender:', error);
    });

  // Remove data for receiver
  remove(ref(db, `chat/${receiver}`))
    .then(() => {
      console.log('Data for receiver deleted successfully');
    })
    .catch((error) => {
      console.error('Error removing data for receiver:', error);
    });

  // Remove data from 'chatwith' node
  remove(ref(db, `chatwith/${curentlogin.value}`))
    .then(() => {
      console.log('Data for current user deleted successfully');
    })
    .catch((error) => {
      console.error('Error removing data for current user:', error);
    });

  remove(ref(db, `chatwith/${chatId}`))
    .then(() => {
      console.log('Data for selected user deleted successfully');
    })
    .catch((error) => {
      console.error('Error removing data for selected user:', error);
    });
      };

    let showuser=()=>{
        let modal = document.getElementById("plist");
        modal.style.display ="block"; 
        modal.style.left ="0";
            }

          let  hideuser = () => {
                let modal = document.getElementById("plist");
                modal.style.display = "none"; // Hide the element
                modal.style.left = "400px"; // Set the left position to 400 pixels (or any other value with a unit)
            }
            const handleFilterChange = (event) => {
                const value = event.target.value;
                setFilterValue(value === '' ? null : value);
              };

              useEffect(() => {
                const fetchData = async () => {
                  try {
                    const formData = new FormData();
                    formData.append('id', curentlogin.value);
                    formData.append('user', curentlogin.value);
            
                    const response = await axios.post('https://domaintobesocial.com/domaintobe/getfriendlist', formData);
            
                    if (response.data.status === 'data') {
                      setFriendsData(response.data.message);
                    } else {
                      alert(response.data.message);
                    }
                  } catch (error) {
                    console.log(error.message);
                  }
                };
            
                fetchData();
              }, [curentlogin.value]);
              const handleEditMessageByTime = (time, oldMessage) => {
                const newMessage = prompt("Edit your message:", oldMessage);
                
                if (newMessage) {
                  const senderRef = ref(db, `chat/${curentlogin.value}_${chatId}`);
                  
                  // Search for the message with the matching time
                  onValue(senderRef, (snapshot) => {
                    snapshot.forEach((snap) => {
                      const messageData = snap.val();
                      
                      if (messageData.time === time) {
                        const messagePath = `chat/${curentlogin.value}_${chatId}/${snap.key}/msg`; // Get the correct message path
                        
                        const updates = {};
                        updates[messagePath] = newMessage;
                        
                        // Update the message in Firebase
                        update(ref(db), updates)
                          .then(() => {
                            console.log("Message updated successfully");
                          })
                          .catch((error) => {
                            console.error("Error updating message:", error);
                          });
                      }
                    });
                  });
                }
              };
              
              
          

              const handleDeleteMessageByTime = (time) => {
                // Find and delete the message in Firebase
                const senderRef = ref(db, `chat/${curentlogin.value}_${chatId}`);
                
                // Listen to all messages to find the one with the matching time
                onValue(senderRef, (snapshot) => {
                  snapshot.forEach((snap) => {
                    if (snap.val().time === time) {
                      // Remove the message with matching time from Firebase
                      remove(ref(db, `chat/${curentlogin.value}_${chatId}/${snap.key}`))
                        .then(() => {
                          console.log("Message deleted from Firebase");
                        })
                        .catch((error) => {
                          console.error("Error deleting message from Firebase:", error);
                        });
                    }
                  });
                });
              
                // Update local state to remove the message from the UI
                setChatingData(chatingdata.filter(chat => chat.time !== time));
              };


              const removeImage = (i) => {
                const newArray = [...imagesPreviewUrls];
                newArray.splice(i, 1);
                setImagesPreviewUrls(newArray);
              };

              const renderMessageContent = (msg) => {
                const urlPattern = /(https?:\/\/[^\s]+)/g; 
                const parts = msg?.split(urlPattern);
              
                const renderedOutput = [];
                let hasMedia = false;
              
                // Flag to track if text has already been rendered
                let hasRenderedText = false;
                
                for (let i = 0; i < parts?.length; i++) {
                  const part = parts[i].trim();
              
                  if (part.match(urlPattern)) {
                    // Check if the URL is an image
                    if (/\.(jpeg|jpg|png|gif)$/i.test(part)) {
                      renderedOutput.push(
                        <Zoom><img
                          key={`img-${i}`}
                          src={part}
                          alt="Image"
                          className="chatimage"
                          style={{ height: '100px', position: 'relative' }}
                        />
                        </Zoom>
                      );
                      hasMedia = true; 
                    } 
                    // Check if the URL is a video
                    else if (part.endsWith('.mp4')) {
                      renderedOutput.push(
                        <video key={`video-${i}`} className="chatvideo" controls>
                          <source src={part} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      );
                      hasMedia = true; 
                    }
                  } else if (part) {
                    // Render text only if no media has been added yet
                    if (!hasMedia && !hasRenderedText) {
                      renderedOutput.push(<p key={`text-${i}`}>{part}</p>);
                      hasRenderedText = true; // Mark that text has been rendered
                    } else if (hasMedia) {
                      // If media has been added, render text only if it hasn't been rendered yet
                      if (!hasRenderedText) {
                        renderedOutput.push(<p key={`text-${i}`}>{part}</p>);
                        hasRenderedText = true; // Mark that text has been rendered
                      }
                    }
                  }
                }
              
                return <>{renderedOutput}</>; // Return the rendered output as a fragment
              };
              


        
      
              
                            
  return (
    <div className="in_center in_center_discussion messagesmain pr-0">

<div className="container">
  <div className="row clearfix">
    <div className="col-lg-12">
      <div className="card chat-app">
        <button className='chatuser btn btn-primary' onClick={showuser}>User List</button> 
        <div id="plist" className="people-list">
      <button className='chatuser chat-btn btn btn-primary' onClick={hideuser}>
        <i className="fas fa-times side_b close"></i>
      </button>
      <div className="input-group ">
       
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={filterValue || ''}
          onChange={handleFilterChange}
        />
         <div className="input-group-prepend">
          <span className="input-group-text">
          {/* <i className="fa-regular fa-paper-plane"></i> */}
          </span>
        </div>
      </div>
      <ul className="list-unstyled chat-list mt-2 mb-0">
  {filteredData.length > 0 ? (
    <>
      {filteredData.map((result, i) => (
        <React.Fragment key={i}>
                {blockdata &&
          blockdata.filter(
            (item) =>
              (
                (item.friendid === result.friendid && item.userid === result.userid) ||
                (item.friendid === result.userid && item.userid === result.friendid)
              ) &&
              item.status === "1"
             
          ).length > 0 ? null : (
            <>
           
              <li
                className="clearfix d-flex"
                onClick={() =>
                  selectUser(i, result.friendid, result.name, result.image, result.userroles,result.email)
                }
              >
                <Link to={{ pathname: `/viewprofile/${result.name}` }}>
                  <img src={result.image} alt="avatar" />
                </Link>
                <div className="about">
                  <div className="name">{result.name}</div>
                  <div className="status">
                    Let's chat {result.name}{" "}
                    <i
                      onClick={() =>
                        selectUserfordelete(i, result.friendid, result.name, result.image)
                      }
                      className="fas fa-trash-alt"
                    ></i>
                  </div>
                </div>
              </li>
            </>
          )}
        </React.Fragment>
      ))}
    </>
  ) : null}
</ul>

    </div>
    <div className="chat">
    <div className="chat-header clearfix">
      <div className="row">
        <div className="col-lg-6">
          <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
          <Link to={{ pathname: `/viewprofile/${chatUsername}` }}>
            <img src={chatUserImage} alt="user" />
            </Link>
          </a>
          <div className="chat-about">
            <h6 className="m-b-0">{chatUsername}</h6>
            <small>Last seen: 2 hours ago</small>
          </div>
        </div>
        <div className="col-lg-6 hidden-sm text-right">
          
        {(curentlogin.userroles==='1' && !helprequestdata) && (
  <span className="mr-5" onClick={() => helprequest()}>Help Request</span>
)}

          <a href="javascript:void(0);" className="btn btn-outline-secondary">
            <i className="fa fa-camera" />
          </a>
          {/* Add other buttons if needed */}
        </div>
      </div>
    </div>
      <div className="chat-history dddd" ref={scrollRef}>
        <ul className="m-b-0">
       
        {chatingdata.map((chat, i) => (
  <React.Fragment key={i}>
    <li className="clearfix">
      {chat.side === 'left' ? (
        // Left-side (other user's message)
        <>
          <div className="message-data">
            <span className="message-data-time">{chat.time}</span>
          </div>
          <div className="message my-message">
            {renderMessageContent(chat.msg)}
          </div>
        </>
      ) : (
        // Right-side (current user's message)
        <>
          <div className="message-data text-right">
            <span className="message-data-time">{chat.time}</span>
            <img src={userImage} alt="avatar" />
          </div>
          <div className="message other-message float-right">
            {renderMessageContent(chat.msg)}
            <div>
              <i
                className="fas fa-edit btn-set"
                onClick={() => handleEditMessageByTime(chat.time, chat.msg)}
              ></i>
              &nbsp;
              <i
                className="fas fa-trash-alt btn-set"
                onClick={() => handleDeleteMessageByTime(chat.time)}
              ></i>
            </div>
          </div>
        </>
      )}
    </li>
  </React.Fragment>
))}
        </ul>
      </div>

      <div className="masseage-area d-flex  align-items-center">
            <ul className="d-flex align-items-center firstleft">
               <li><Link href="#"><i className="fa-solid fa-plus"></i></Link></li>
               {/* <li><Link href="#" ><i className="fa-solid fa-camera"></i></Link></li> */}
               <li><Link href="#"  onClick={() => document.getElementById('file-upload').click()} ><i className="fa-regular fa-image"></i></Link></li>
               <li><Link href="#"><i className="fa-solid fa-microphone"></i></Link></li>
            </ul>
      
            <ul className="d-flex align-items-center secondright2"  >
            <form className="w-100 d-flex align-items-center  secondright p-0" onSubmit={submitChat}>
               <li className="inputarea"><textarea type="text" rows="1" cols="100" maxlength="200"   onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); 
        }
      }} placeholder="Aa"   value={inputMessage}className='form-control' onChange={handleChange}/> <i className="fa-regular fa-face-smile"></i></li>
      {console.log(chatuserroles)}
      {
  curentlogin.userroles === '3' ? (
    <li>
      <button type="submit" className="">
        <i className="fas fa-paper-plane"></i>
      </button>
    </li>
  ) : curentlogin.userroles === chatuserroles ? (
    <li>
      <button type="submit" className="">
        <i className="fas fa-paper-plane"></i>
      </button>
    </li>
  ) : helprequestdata ? (
    <li>
      <button type="submit" className="">
        <i className="fas fa-paper-plane"></i>
      </button>
    </li>
  ) : (
    <li>
      <button
        type="button"
        data-toggle={chatuserroles !== curentlogin.userroles ? 'modal' : undefined}
        data-target={chatuserroles !== curentlogin.userroles ? '#exampleModalCenter' : undefined}
        className=""
      >
        <i className="fas fa-paper-plane"></i>
      </button>
    </li>
  )
}

              </form>
            </ul>
         </div>
         <input id="file-upload" type="file" onChange={handleImageChange} style={{ display: 'none' }}   accept="image/*, .jpg, .jpeg, .png" />
      {/* <div className="chat-message clearfix">
      {imagesPreviewUrls.map((imagePreviewUrl, i) => (
                <div className="col-4 col-sm-3 col-lg-2" onClick={() => removeImage(i)}>
                  <div className="imgg">
                    <i className="fa fa-times"></i>
                    <img className="upim w-100" key={imagePreviewUrl} alt="previewImg" src={imagePreviewUrl} />
                  </div>
                </div>
              ))}
        <div className="input-group mb-0">
      
          <form className="form-control p-0" onSubmit={submitChat}>
            <input
              type="text"
              className="form-control"
              placeholder="Enter text here..."
              onChange={handleChange}
              value={inputMessage}
              name="message"
            />
           
            {imagesPreviewUrls.length !== 0 ? (
              <button type="submit" className='send-paper-upload'>
                <i className="fas fa-paper-plane"></i>&nbsp; <span>upload</span>
              </button>
            ) : (
              ''
            )}
                <div className="input-group-prepend send-paper">
            <span className="input-group-text">
            <i className="fa-regular fa-paper-plane"></i>
            </span>
          </div>
       

         
           


          </form>
        </div>
      </div> */}
    </div>
        </div>
        </div>
        </div>
        </div>
            </div>
      
  )
}

export default Messages