import React from 'react'
import Header from './Compononets/Header-site/Header'

const Support = () => {
  return (
    <>

<div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className='private-policy'>
              <h1>Support</h1>
              <p>
              cashforpropertys1@gmail.com 
              </p>
            </div>
          </div>
        </div>
      </div>

    
    
    </>
  )
}

export default Support