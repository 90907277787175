import axios from 'axios';
import React, { useState } from 'react'
import swal from 'sweetalert';
import $ from 'jquery'

const Suggestionbox = () => {

    const[Subject,setSubject]=useState('')
    const[Message,setMessage]=useState('')
    let curentlogin = JSON.parse(window.localStorage.getItem("user"));
    const handleSubmit =  (event) => {
        event.preventDefault();
      
        
        // Make Axios request to change password
        try {
            const formData = new FormData();
            formData.append("userid", curentlogin.value);
            formData.append("subject", Subject);
            formData.append("message", Message);
  
          const response =  axios.post('https://domaintobesocial.com/domaintobe/suggestion',formData );
         
      swal('suggestion send')
      
          setMessage('');
          setSubject('');

        } catch (error) {
          // Handle error
          console.error('Error:', error);
         
        }
      };
  return (
    <>
<button type="button" className="btn btn-success mt-3" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Suggestion Box</button>
<div
  className="modal fade"
  id="exampleModal"
  tabIndex={-1}
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header p-2">
        <h5 className="modal-title" id="exampleModalLabel">
        suggestion box
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body p-2">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">
              Subject:
            </label>
            <input type="text" className="form-control" id="recipient-name" name='subject' value={Subject} onChange={(e)=>setSubject(e.target.value)} />
          </div>
          <div className="form-group">
            <label htmlFor="message-text" className="col-form-label">
              Message:
            </label>
            <textarea
              className="form-control"
              id="message-text"
             
              name='message'
              value={Message} onChange={(e)=>setMessage(e.target.value)}
            />
          </div>
    <div className='float-right'>
    <button
          type="button"
          className="btn btn-success mr-2"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="submit" className="btn btn-success ">
          Send message
        </button>
    </div>
        </form>
      </div>
    
    </div>
  </div>
</div>

    </>
  )
}

export default Suggestionbox