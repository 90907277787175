import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import axios from "axios";
import "reactjs-popup/dist/index.css";

const Signup = () => {
  console.log(window.promotekit_referral);
  console.log("davey");
  const navigate = useNavigate();
  const location = useLocation();
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://domaintobesocial.com/domaintobe/get_coupon"
        );
        setPopupData(response.data.data[0]);
        setPopupOpen(true);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch data when the component mounts
    fetchData();
    const timeoutId = setTimeout(() => {
      setPopupOpen(true);
    }, 4000);
    // Cleanup function to clear the timeout
    return () => clearTimeout(timeoutId);
  }, []);

  const redirectToVipUser = () => {
    const searchQuery = location.search
      ? `/?${location.search.substring(1)}`
      : "";
    navigate(`/vipuser${searchQuery}`);
  };

  return (
    <section
      className="loginpagetow signup1"
      style={{ backgroundImage: `url(/images/signupbg.jpg)` }}
    >
      <div className="container">
        {popupOpen && popupData?.value && (
          <Popup open={popupOpen} position="right center">
            <div className="coupon">
              <div className="containerpopup">
                <h3>
                  <img src="/images/logo.png" alt="Avatar" />
                </h3>
              </div>
              <div className="container" style={{ backgroundColor: "white" }}>
                <h2>
                  {popupData &&
                  popupData?.value &&
                  popupData.type === "percentage" ? (
                    <b>{popupData?.value}% OFF Signup VIP </b>
                  ) : (
                    <b>{popupData?.value} fixed OFF Signup VIP </b>
                  )}
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, et nam pertinax gloriatur. Sea te
                  minim soleat senserit, ex quo luptatum tacimates voluptatum,
                  salutandi delicatissimi eam ea. In sed nullam laboramus
                  appellantur, mei ei omnis dolorem mnesarchum.
                </p>
              </div>
              <div className="containerpopup">
                <p>
                  Use Promo Code:{" "}
                  <span className="promo">
                    {popupData?.coupon_code && popupData?.coupon_code}
                  </span>
                </p>
                <p className="expire">
                  Expires:{" "}
                  {popupData?.end_date &&
                    new Date(popupData?.end_date * 1000).toLocaleString()}
                </p>
              </div>
              <button
                className="btn btn-primary"
                onClick={() => setPopupOpen(false)}
              >
                Close modal
              </button>
            </div>
          </Popup>
        )}
        <Link to="/" className="gologin">
          <img src="/images/loginarrow2.png" alt="icon" />
        </Link>
        <h2>Signup with</h2>
        <div className="row">
          <div className="col-sm-6">
            <Link to="/Signup">
              <div className="signone bird">
                <div className="images">
                  <img src="/images/useri_1.png" alt="images" />
                </div>
                <ul>
                  <li>
                    1. <span>Quick Posting</span> using feed along with features
                    like attaching photo, video, tagging user and using emojis
                  </li>
                  <li>
                    2. <span>Posting on Message</span> board to start a
                    discussion thread under particular created(Category/sub
                    category managed by administrator)
                  </li>
                  <li>
                    3. <span>Posting feed</span> or Discussion thread as private
                    with custom duration and password access with share
                    function.
                  </li>
                  <li>
                    4. <span>Marking profile</span> hidden while adding comments
                  </li>
                  <li>
                    5. <span>Creating custom</span> profile page with different
                    colour theme, Banner, photos, video, bio, URL and Location
                  </li>
                  <li>
                    6. <span>Posting feed</span> or Discussion thread as private
                    with custom duration and password access with share function
                  </li>
                  <li>
                    7. <span>To get discounts</span> on membership renewal by
                    allowing ads on profile page
                  </li>
                  <li>
                    8. <span>To receive requests</span> for chat , video call
                    and Help information
                  </li>
                </ul>
              </div>
            </Link>
          </div>
          <div className="col-sm-6">
            <Link
              to={
                location.search
                  ? "/vipuser?" + location.search.substring(1)
                  : "/vipuser"
              }
            >
              <div className="signtwo ">
                <div className="images">
                  <img src="/images/useri_2.png" alt="images" />
                  <img className="vip" src="/images/vip.png" alt="images" />
                </div>
                <ul>
                  <li>
                    1. <span>Quick Posting</span> using feed along with features
                    like attaching photo, video, tagging user and using emojis
                  </li>
                  <li>
                    2. <span>Posting on Message</span> board to start a
                    discussion thread under particular created(Category/sub
                    category managed by administrator)
                  </li>
                  <li>
                    3. <span>Marking profile</span> hidden while adding comments
                  </li>
                  <li>
                    4. <span>Creating custom</span> profile page with different
                    colour theme, Banner, photos, video, bio, URL and Location
                  </li>
                  <li>
                    5. <span>Posting feed</span> or Discussion thread as private
                    with custom duration and password access with share function
                  </li>
                  <li>
                    6. <span>To get discounts</span> on membership renewal by
                    allowing ads on profile page
                  </li>
                  <li>
                    7. <span>To receive requests</span> for chat , video call
                    and Help information
                  </li>
                </ul>
              </div>
            </Link>
          </div>

          <div className="col-sm-12 text-center mt-4">
            {location.search ? (
              <Link
                to={`/normalsignup?${location.search.substring(1)}`}
                className="nextbutton"
              >
                Next
              </Link>
            ) : (
              <Link to="/normalsignup" className="nextbutton">
                Next
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
