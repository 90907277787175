import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

function Requests() {
    let currentLogin = JSON.parse(window.localStorage.getItem("user"));
    const [friendsrequests, setFriendsRequests] = useState([]);

    useEffect(() => {
        const fetchFriendRequests = async () => {
            try {
                const formData = new FormData();
                formData.append('id', currentLogin.value);

                const response = await axios.post('https://domaintobesocial.com/domaintobe/getfriendrequests', formData);

                if (response.data.status === 'data') {
                    setFriendsRequests(response.data.message);
                } else {
                    alert(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching friend requests:', error.message);
                alert('Invalid Login1');
            }
        };

        fetchFriendRequests();
    }, [currentLogin.value, setFriendsRequests]);

    function acceptFriendRequest(i,id) {
        const formData = new FormData();
        formData.append('id', id);

        axios.post('https://domaintobesocial.com/domaintobe/acceptfriendrequests', formData)
            .then((res) => {
                if (res.data.message === 'success') {
                    // You might want to update friendsrequests here if needed
                    swal("Successfully", "you are now friends", "success");
                } else {
                    swal(res.data.message);
                }
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    return (
        <div className="in_center in_center_discussion">
            <div>
                <div className="head pr-0"> 
                    <form className="d-flex w-100">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn" type="submit"><img src="images/searchicon.png" alt="icon" /> </button>
                    </form>
                </div>
                <div className="my_followers">
                    <div className="row">
                        {friendsrequests && friendsrequests.length > 0 ? friendsrequests.map((resultf, i) => (
                            <div key={resultf.id} className="col-lg-6 col-xl-4">
                                <div className="test">
                                <Link
                        to={{ pathname: "/viewprofile/" + resultf?.name }}
                      >   <span className="userimg">
                                        <img src={resultf.image} align="icon" alt={resultf.name} />
                                    </span>
                                    <h5>{resultf.name}</h5></Link>
                                    <ul className="followmessage">
                                        <li><a onClick={() => acceptFriendRequest(i, resultf.id)}>Accept Request</a></li>
                                        <li><Link className="mg" to="/messages">Message</Link></li>
                                    </ul>
                                </div>
                            </div>
                        )) : (
                            <div className="norecord">
                                <img src="/images/nodata.png" alt="No data" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Requests;
